<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">培训招生</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl ">
          <div class="searchbox">
            <div title="报名时间" class="searchboxItem ci-full">
              <span class="itemLabel">报名时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="SignUpTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="btnBox" style="margin-left:20px">
              <el-button
                style="margin-left:20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="realname"
                show-overflow-tooltip
              />
              <el-table-column
                label="联系电话"
                align="left"
                prop="phone"
                show-overflow-tooltip
              />
              <el-table-column
                label="培训工种"
                align="left"
                prop="occupationNamePath"
                show-overflow-tooltip
              />
              <el-table-column
                label="培训等级"
                align="left"
                prop="trainLevelName"
                show-overflow-tooltip
              />
              <el-table-column
                label="报名时间"
                align="center"
                show-overflow-tooltip
                prop="createTime"
              >
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "appSignUpList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      SignUpTime:[],
    };
  },
  computed: {},
  created() {
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.SignUpTime && this.SignUpTime.length) {
        params.startTime = this.SignUpTime[0] + ' 00:00:00';
        params.endTime = this.SignUpTime[1] + ' 23:59:59';
      }
      this.doFetch({
        url: "/run/rotation/form/page",
        params,
        pageNum
      });
    },

    //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 - 60;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
</style>
<style lang="less" scoped>
</style>
